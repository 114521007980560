import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PrivateRoute from './routing/PrivateRoute'
import { Footer} from './components';
import { SignIn, SignUp, Welcome, Reset, Recover } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  //console.log(user + " from context");
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Switch>
          {/* auth  */}
          <PrivateRoute path="/signin" component={SignIn} />
          <PrivateRoute path="/signup" component={SignUp} />
          <PrivateRoute path="/welcome/school" component={Welcome} />
          <Route path="/auth/reset" component={Reset} />
          <Route path="/auth/recover" component={Recover} />
          <PrivateRoute path="/" exact component={Recover} />
          {/* end switch  */}
          <div className="flex relative dark:bg-main-dark-bg">
            <div className={ activeMenu ? 'dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full' : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2' }>
              <Footer />
            </div>
          </div>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
