import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { publicRequest } from '../../utils/requestMethods';
import './css/default.css'
import './css/style.css'
import { ButtonLoading } from '../../components';
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import flower from './img/flower.png';
import man2 from './img/man-2.png';
import man3 from './img/man-3.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';

const SignIn = ({ history }) => {
   const [firstname, setFirstname] = useState("");
   const [lastname, setLastname] = useState("");
   const [school_name, setSchool_name] = useState("");
   const [mobile_number, setMobile_number] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [device, setDevice] = useState({
      "ip": "string",
      "user_agent": "string",
      "os": "string"
   });
   const [success, setSuccess] = useState("");
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);

   const registerHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (!firstname || !lastname || !school_name || !mobile_number || !email || !password || !confirmPassword) {
         setError("Please Fill all the Feilds");
         setTimeout(() => {
            setError("");
         }, 5000);
         setLoading(false);
         return;
      }

      if (password !== confirmPassword) {
         setError("Passwords do not match");
         setPassword("");
         setConfirmPassword("");
         setTimeout(() => {
            setError("");
         }, 5000);
         return;
      }

      try {
         const { data } = await publicRequest.post("auth/school",
            {
               firstname,
               lastname,
               school_name,
               mobile_number,
               email,
               password,
               device,
            });

         //localStorage.setItem("accUser", JSON.stringify(data.school));
         //console.log(data.school);
         setSuccess("Account created successfully");
         setTimeout(() => {
            setSuccess("");
            history.push("/welcome/school");
         }, 5000);
      } catch (error) {
         console.log(error);
         setError(error.response.data);
         setTimeout(() => {
            setError("");
         }, 5000);
         setLoading(false);
      }
   };

    return (
        <main>
          <section className="signup__area po-rel-z1 p-t-40 pb-100">
            <div className="sign__shape">
               <img className="man-1" src={man3} alt="" />
               <img className="man-2" src={man2} alt="" />
               <img className="circle" src={circle} alt="" />
               <img className="zigzag" src={zigzag} alt="" />
               <img className="dot" src={dot} alt="" />
               <img className="bg" src={signup} alt="" />
               <img className="flower" src={flower} alt="" />
            </div>
            <div className="row">
               <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                  <div className="section__title-wrapper text-center m-b-40">
                     <img src={logo} alt="logo" className="mx-auto" width="250" />
                  </div>
               </div>
            </div>
            <div className="max-w-xl mx-auto divide-y p-3">
               {success &&
                  <div className="alert alert-success d-flex align-items-center" role="alert">
                     <i className="fal fa-check pr-10"></i>
                     <div>
                        {success}
                     </div>
                  </div>
               }
               {error && 
                  <div className="alert alert-warning d-flex align-items-center" role="alert">
                     <i className="fal fa-lock pr-10"></i>
                     <div>
                        {error}
                     </div>
                  </div>
               }
               <div className="sign__wrapper white-bg mx-auto">
                  <div className="sign__header mb-35">
                     <div className="sign__in text-center">
                        <h3 className="text-2xl font-bold section__title">Create a free Account.</h3>
                     </div>
                  </div>
                  <div className="sign__form">
                      <form onSubmit={registerHandler}>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Full Name</h5>
                           <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                              <div className="sign__input mb-2">
                                 <input type="text" placeholder="First name" onChange={(e) => setFirstname(e.target.value)} required/>
                                 <i className="fal fa-user"></i>
                              </div>
                              <div className="sign__input">
                                 <input type="text" placeholder="Last name" onChange={(e) => setLastname(e.target.value)} required />
                                 <i className="fal fa-user"></i>
                              </div>
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Business/School Name</h5>
                           <div className="sign__input">
                              <input type="text" placeholder="School name" onChange={(e) => setSchool_name(e.target.value)} required/>
                              <i className="fal fa-school"></i>
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Mobile Number</h5>
                           <div className="sign__input">
                              <input type="tel" placeholder="Mobile Number" onChange={(e) => setMobile_number(e.target.value)} required/>
                              <i className="fal fa-phone"></i>
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Work email</h5>
                           <div className="sign__input">
                              <input type="email" placeholder="e-mail address" onChange={(e) => setEmail(e.target.value)} required/>
                              <i className="fal fa-envelope"></i>
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Password</h5>
                           <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                              <div className="sign__input mb-2">
                                 <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/>
                                 <i className="fal fa-lock"></i>
                              </div>
                              <div className="sign__input">
                                 <input type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} required/>
                                 <i className="fal fa-lock"></i>
                              </div>
                           </div>
                        </div>
                        <div className="sign__action d-sm-flex justify-content-between mb-30">
                            <div className="sign__agree d-flex align-items-center">
                                <input className="m-check-input" type="checkbox" id="m-agree" required/>
                                <label className="m-check-label" for="m-agree">
                                    I agree to the <Link to="/">Terms & Conditions</Link>
                                </label>
                           </div>
                        </div>
                        {loading ?
                           (
                              <ButtonLoading styles="e-btn w-100 auth-btn" />
                           ) : (
                              <button className="e-btn w-100 auth-btn"> <span></span> Sign Up</button>
                           )
                        }
                        <div className="sign__new text-center mt-10">
                           <p>Already on Acceede? <Link to="/signin">Sign In</Link></p>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </main> 
    );
};
export default SignIn;
