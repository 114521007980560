import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { publicRequest } from '../../utils/requestMethods';
import { ButtonLoading } from '../../components';
import './css/default.css'
import './css/style.css'
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';

const Reset = ({ history }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const verifyHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        //console.log(code);
        if ( !email ) {
            setError("Please Fill all the Feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }

        try {
            await publicRequest.post(`auth/forget-password`, { email });

            setSuccess("Reset code sent successfully");
            setTimeout(() => {
                setSuccess("");
                history.push("/auth/recover");
            }, 5000);
        } catch (error) {
            //console.log(error.response.data);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <main>
            <section className="signup__area po-rel-z1 p-t-40 pb-100">
                <div className="sign__shape">
                    <img className="circle" src={circle} alt="" />
                    <img className="zigzag" src={zigzag} alt="" />
                    <img className="dot" src={dot} alt="" />
                    <img className="bg" src={signup} alt="" />
                </div>
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center m-b-40">
                            <img src={logo} alt="logo" className="mx-auto" width="250" />
                        </div>
                    </div>
                </div>

                <div className="max-w-xl mx-auto mt-3 divide-y p-3">
                    {success &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <i className="fal fa-check pr-10"></i>
                            <div>
                                {success}
                            </div>
                        </div>
                    }
                    {error &&
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <div className="sign__wrapper bg-white mx-auto">
                        <div className="sign__form px-2 text-center">
                            <h3 className="text-2xl font-bold text-center">Forgot Password?</h3>
                            <p className="mb-25">Provide your email address below, a reset code and instructions will be sent to your mailbox.</p>

                            <div className="sign__input-wrapper mb-25">
                                <div className="col-md-4 sign__input">
                                    <input type="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} required />
                                    <i className="fal fa-envelope"></i>
                                </div>
                            </div>

                            {loading ?
                                (
                                    <ButtonLoading styles="e-btn w-100 auth-btn" />
                                ) : (
                                    <button onClick={verifyHandler} className="e-btn w-100 auth-btn"> Send reset code</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default Reset;