import axios from "axios";

//const BASE_URL = "https://acceedeapi.herokuapp.com" //Production
//const BASE_URL = "https://production-imh7.onrender.com"
const BASE_URL = "https://staging-s55s.onrender.com" //staging
//const BASE_URL = "https://dev-djnt.onrender.com" //development
//const BASE_URL = "https://acceede.herokuapp.com/api/"

const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
//const user = JSON.parse(localStorage.getItem("userInfo"));
//const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = tokenWithUser?.access_token;

console.log(tokenWithUser);

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    "x-access-token": ` ${TOKEN}`,
  },
});

// Create our Currency formatter.
export const formatter = new Intl.NumberFormat('en-NG', {
  style: 'currency',
  currency: 'NGN',
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as ₦2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as ₦2,501)
});

//(2500).toLocaleString("en-NG", {style: "currency", currency: "NGN", minimumFractionDigits: 2})